// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-faq-index-jsx": () => import("./../../../src/pages/faq/index.jsx" /* webpackChunkName: "component---src-pages-faq-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-precios-index-jsx": () => import("./../../../src/pages/precios/index.jsx" /* webpackChunkName: "component---src-pages-precios-index-jsx" */),
  "component---src-pages-soluciones-call-center-index-jsx": () => import("./../../../src/pages/soluciones/call-center/index.jsx" /* webpackChunkName: "component---src-pages-soluciones-call-center-index-jsx" */),
  "component---src-pages-soluciones-kds-index-jsx": () => import("./../../../src/pages/soluciones/kds/index.jsx" /* webpackChunkName: "component---src-pages-soluciones-kds-index-jsx" */),
  "component---src-pages-soluciones-menu-digital-index-jsx": () => import("./../../../src/pages/soluciones/menu-digital/index.jsx" /* webpackChunkName: "component---src-pages-soluciones-menu-digital-index-jsx" */),
  "component---src-pages-soluciones-pagina-web-index-jsx": () => import("./../../../src/pages/soluciones/pagina-web/index.jsx" /* webpackChunkName: "component---src-pages-soluciones-pagina-web-index-jsx" */),
  "component---src-pages-soluciones-sistema-pos-index-jsx": () => import("./../../../src/pages/soluciones/sistema-pos/index.jsx" /* webpackChunkName: "component---src-pages-soluciones-sistema-pos-index-jsx" */)
}

